import React, { FC, useEffect, useRef, useState } from 'react';
import { isString } from 'lodash';
import { Icon, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import LogoImage from '../../assets/logos/logo-ys-2022.png';

import { BackgroundWrapperProps, PageSplitProps, testIds } from './PageSplit.model';
import {
  BackNavigation,
  ContentHeader,
  ContentHolder,
  ContentLeft,
  ContentRight,
  FooterContentLeft,
  FooterContentRight,
  LeftPageContent,
  Logo,
  LogoSecondary,
  LogosHolder,
  RightPageContent,
  SPageSplit,
} from './PageSplit.style';

const BackgroundWrapper: FC<BackgroundWrapperProps> = ({ backgroundImage, children }) => (
  <>{backgroundImage && !isString(backgroundImage) ? backgroundImage(children) : children}</>
);

export const PageSplit: FC<PageSplitProps> = ({
  showLogo,
  secondLogo,
  backNavigation,
  backNavigationText,
  backNavigationCallback,
  title,
  titleTagType,
  label,
  sideContent,
  footerContent,
  backgroundColor,
  hideBackgroundImageMobile,
  backgroundImage,
  middleSplit,
  children,
  className,
}) => {
  const [headerHeight, setHeight] = useState(0);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      (showLogo || backNavigation) &&
      elementRef?.current?.offsetHeight &&
      headerHeight !== elementRef.current.offsetHeight
    ) {
      setHeight(elementRef?.current?.offsetHeight);
    }
  }, [backNavigation, elementRef, headerHeight, showLogo]);

  return (
    <SPageSplit backgroundColor={backgroundColor} className={className} {...testIds.base.attr}>
      <LeftPageContent middleSplit={middleSplit} className="page-split-left-content">
        <ContentHolder>
          <ContentHeader ref={elementRef}>
            {showLogo ? (
              <LogosHolder
                className="page-split-logos-holder"
                center={!secondLogo}
                {...testIds.logo.attr}
              >
                <Logo>
                  <a href="/">
                    <img width="177" src={LogoImage} />
                  </a>
                </Logo>
                {secondLogo ? (
                  <>
                    <Icon sx={{ mx: 2 }}>
                      <AddIcon />
                    </Icon>
                    <LogoSecondary>{secondLogo}</LogoSecondary>
                  </>
                ) : null}
              </LogosHolder>
            ) : null}
            {backNavigation ? (
              <BackNavigation
                direction="row"
                onClick={e => {
                  e.preventDefault();
                  backNavigationCallback && backNavigationCallback();
                }}
                {...testIds.backNavigation.attr}
              >
                <ChevronLeftIcon sx={{ mr: 2 }} />
                <Typography component="span">{backNavigationText}</Typography>
              </BackNavigation>
            ) : null}
          </ContentHeader>
          <ContentLeft>
            {label ? (
              <Typography
                component="p"
                variant="body1"
                sx={{ marginBottom: 5 }}
                {...testIds.label.attr}
              >
                {label}
              </Typography>
            ) : null}
            {title ? (
              <Typography
                component={titleTagType ? `h${titleTagType}` : 'h3'}
                variant="h3"
                sx={{ marginBottom: 5 }}
                {...testIds.title.attr}
              >
                {title}
              </Typography>
            ) : null}
            {sideContent}
          </ContentLeft>
        </ContentHolder>
        {footerContent && <FooterContentLeft>{footerContent}</FooterContentLeft>}
      </LeftPageContent>
      <BackgroundWrapper backgroundImage={backgroundImage}>
        <RightPageContent
          backgroundImage={backgroundImage}
          hideBackgroundImageMobile={hideBackgroundImageMobile}
          middleSplit={middleSplit}
          headerHeight={headerHeight}
        >
          <ContentRight>{children}</ContentRight>
        </RightPageContent>
      </BackgroundWrapper>
      {footerContent && <FooterContentRight>{footerContent}</FooterContentRight>}
    </SPageSplit>
  );
};
